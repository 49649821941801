.App {
  
  text-align: center;
}
.selecteListMaterial {
  width: 150px;
  height: 55px;
  border-color: #4EBBB7;
  border-style: solid;
  border-radius: 19px;
  border-width: 3px;
  display: flex;
  font-size: 20px;
  color: #817b7b;
  outline: none;
  text-align: center;
}
.title {
  font-style: normal;
  margin-bottom: 1rem;  
  font-weight: 600;
  display: flex;
  align-items: center;
  color: #4EBBB7;
}

.contet{
  font-style: normal;  
  color: #4EBBB7;
  font-weight: 500;
  line-height: 48px;
  display: flex;
  align-items: center;

}

.h1 {
  font-style: normal;  
  font-weight: 490;  
  align-items: center;
  text-transform: none;
}

.h4 {
  font-style: normal;
  font-weight: 590;  
  align-items: center;
  text-transform: none;
}
.topMenuMaterial {
  width: 180%;
  height:0px;
  list-style-type: none;
  display: flex; /* Para hacer que el elemento tan solo ocupe lo que sus hijos marque */
  align-items: flex-start; /* Para alinear items flexibles respecto al eje secundario */
  justify-content:center
}