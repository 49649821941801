.title {
  font-style: normal;  
  font-weight: 490;
  align-items: center;
  text-transform: none;
}

.h1 {
  font-style: normal;  
  font-weight: 490;
  text-transform: none;
}
.changecolorYoutube {
	filter: contrast(700%);
  cursor: 'pointer';
  min-width:70px;
  position:'absolute';
  margin-left:-255%;
  margin-top:100%;
}
.topMenuP {
  width: 100%;
  list-style-type: none;
  display: inline-flex;
  justify-content: space-between;
}

.topMenulist {
  width: 100%;
  list-style-type: none;
  display: inline-flex;
  align-items: flex-end;
  justify-content: space-between;
}