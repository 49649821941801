.inputMaterial {
  width: 60%;
  cursor: pointer;
  height: 37px;
  background-color: #f6fdfd;
  
  font-size: 20px;
  border-color: #4EBBB7;
  text-transform: capitalize;
  border-style: solid;
  border-radius: 10px;
  border-width: 3px;
  color: #817b7b;
  outline: none;
}

.inputMateriallistcountry {
  
  font-size: 20px;
  width: 105%;
  margin-inline-end: 20px;
  cursor: pointer;
  height: 37px;
  border-radius: 10px;
  border-color: #4EBBB7;
  text-transform: capitalize;
  border-style: solid;
  border-radius: 10px;
  border-width: 3px;
  color: #817b7b;
  outline: none;
}

.inputMateriallistcountry2 {
  
  font-size: 20px;
  width: 140%;
  cursor: pointer;
  height: 37px;
  border-radius: 10px;
  border-color: #4EBBB7;
  text-transform: capitalize;
  border-style: solid;
  border-radius: 10px;
  border-width: 3px;
  color: #817b7b;
  outline: none;
}

.inputMateriallistcountry4 {
  
  font-size: 20px;
  width: 105%;
  cursor: pointer;
  height: 37px;
  border-radius: 10px;
  border-color: #4EBBB7;
  text-transform: capitalize;
  border-style: solid;
  border-radius: 10px;
  border-width: 3px;
  color: #817b7b;
  outline: none;
}

.inputMateriallistcountry3 {
  
  font-size: 20px;
  width: 140%;
  cursor: pointer;
  height: 37px;
  border-radius: 10px;
  border-color: #4EBBB7;
  text-transform: capitalize;
  border-style: solid;
  border-radius: 10px;
  border-width: 3px;
  color: #817b7b;
  outline: none;
}
.inputMateriallistcountry5 {
  
  font-size: 20px;
  cursor: pointer;
  height: 437%;
  border-radius: 10px;
  border-color: #4EBBB7;
  text-transform: capitalize;
  border-style: solid;
  border-radius: 10px;
  border-width: 3px;
  color: #817b7b;
  outline: none;
}
.Campos1 {
  position: relative;
  display: inline;
  align-items: center;
  left: 10%;
}

.Campos2 {
  margin-left: 10%;
  left: 38%;
  max-width: 300px;
  align-items: center;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.input_container {
  width: 50%;
  display: grid;
  grid-template-columns: 2fr 2fr;
}

.checkInput {
  margin-left:11%;
}

.titleSus {
  font-style: normal;
  color: #4EBBB7;
  font-weight: 600;
  line-height: 48px;
  display: flex;

}

.contetSus {
  font-style: normal;
  font-weight: 490;
  text-transform: none;
}

.contetSus2 {
  font-style: normal;
  font-weight: 690;
  text-transform: none;
}

.selecteListCountry {
  width: 260px;
  max-width: 260px;
  height: 55px;
  border-color: #4EBBB7;
  border-style: solid;
  border-radius: 10px;
  border-width: 3px;
  display: flex;
  font-size: 20px;
  color: #817b7b;
  outline: none;
}