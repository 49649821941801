.App {
  font-family: 'Sofia Sans';
}

.title {
  font-family: 'Sofia Sans';
  font-style: normal;
  margin-bottom: 1rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  color: #4EBBB7;
}

.subTitleCTel {
  color: #4EBBB7;
  font-family: 'Sofia Sans';
  font-style: italic;
  font-weight: lighter;
  font-size: 44px;
  line-height: 1px;
}

.title2 {
  font-style: normal;
  margin-bottom: 1rem;
  font-family: 'Sofia Sans';
  font-weight: 600;
  display: flex;
  align-items: center;
}

.contet {
  font-style: normal;
  font-family: 'Sofia Sans';
  color: #4EBBB7;
  font-weight: 500;
  line-height: 48px;
  display: flex;
  align-items: center;

}

.h1 {
  font-style: normal;
  font-family: 'Sofia Sans';
  font-weight: 490;
  align-items: center;
  text-transform: none;
}

.h4 {
  font-style: normal;
  font-family: 'Sofia Sans';


  font-weight: 590;
  align-items: center;
  text-transform: none;
}

.topMenuMaterial {
  width: 180%;
  height: 0px;
  list-style-type: none;
  display: flex;
  align-items: flex-start;
  justify-content: center
}

.selecteListCountryProyectCTI {
  height: 40px;
  border-color: #4EBBB7;
  border-style: solid;
  border-radius: 10px;
  border-width: 3px;
  display: flex;
  font-size: 20px;
  color: #817b7b;
  outline: none;
}

.inputMaterialclumns {
  width: 30rem;
  cursor: pointer;
  height: 37px;
  font-size: 20px;
  border-color: #4EBBB7;
  text-transform: capitalize;
  border-style: solid;
  border-radius: 10px;
  border-width: 3px;
  color: #817b7b;
  outline: none;
}