.titleInitio {
  font-family: 'Sofia Sans';
  font-style: normal;  
  font-weight: bold;
  align-items: center;
}

.textInformativo {  
  font-style: normal;  
  font-family: 'Sofia Sans';
}

.topMenu1 {  
  font-style: normal;  
  font-family:'Sofia Sans';
  width: 100%;
  list-style-type: none;
  display: inline-flex;
  align-items: flex-start;
  justify-content: space-between;
}