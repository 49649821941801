.title {
	font-style: normal;
	margin-bottom: 1rem;
	font-family:'Sofia Sans';	
	font-weight: 600;
	display: flex;
	align-items: center;
	color: #59E2EB;
}
.subtitle {
	font-style: normal;
	margin-bottom: 1rem;
	font-family:'Sofia Sans';	
	font-weight: 00;
	display: flex;
	align-items: center;
}
.textCont{
	width: '100%';
	font-family:'Sofia Sans'
}

.textModal {
	font-style: normal;
	font-size: 120%;
	font-family:'Sofia Sans';
	
	font-weight: 490;
	align-items: center;
	text-transform: none;
	background-color: #fff;
	color: #23088E;
	border-color: #fff;
}
.textModal2 {
	overflow-y: hidden;
	font-style: normal;
	font-size: 100%;	
	font-family:'Sofia Sans';
	color: #656262;
	align-items: center;
	text-transform: none;
	background-color: #fff;
	border-color: #fff;
}
.titleProyect{	
	font-weight: 550;	
	font-family:'Sofia Sans';
}

.h3 {
	font-style: normal;
	font-family:'Sofia Sans';
	
	font-weight: 490;
	align-items: center;
	text-transform: none;
	color: #a3a3a3;
}


.topMenuBlog {
	width: 100%;
	height: 25px;
	list-style-type: none;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}

.modalPodCats {
	font-family:'Sofia Sans';
	font-style: normal;
	position: absolute;
	border-radius: 30px;
}

.modalComent {
	font-family:'Sofia Sans';
	font-style: normal;
	position: absolute;
	top: 7%;
	left: 34%;
}

/*  */
main {
	background-color: #4EBBB7;
	width: 490px;
	height: auto;
	font-size: 15px;
	font-family:'Sofia Sans';
	border-radius: 2%;
	border-style: solid;
	border-color: #4EBBB7;
}


#chat {
	padding-left: 0;
	margin: 10px 10px 10px;
	overflow-y: auto;
	height: 420px;
	font-family:'Sofia Sans';
	overflow-x: hidden;
}

#chat li {	
	padding: 10px 3px;
}

#chat h2,
#chat h3 {
	font-size: 13px;
	font-weight: normal;
	
}

#chat .message {	
	background-color: #fff;
	display: flex;
	padding: 20px;
	color: #6d6868;
	line-height: 25px;
	font-family:'Sofia Sans';
}


#chat .you .message {
	border-top-left-radius: 3rem;
	border-top-right-radius:2rem;
	border-bottom-right-radius: 2rem;
}

#chat .me .message {
	border-top-left-radius: 2rem;
	border-top-right-radius: 4rem;
	border-bottom-left-radius: 2rem;
}

.nameUser{
	font-weight: 490;
	width: 490;	
}

main footer {
	padding: 50px 0px 10px 20px;
}

main footer textarea {	
	width:85%;
	min-width:85%;
	max-width:85%;
	height: 4%;
	overflow-y: auto;
	padding: 20px;	
	font-size: 18px;
	color: #6d6868;
}
.selecteListMeses {
	width:200px;
	height: 55px;
	font-weight: bolder;
	border-color: #fff;
	border-style: solid;
	border-radius: 19px;
	border-width: 3px;
	display: flex;
	font-size: 30px;
	color: #4EBBB7;
	outline: none;
	text-align: center;
	font-family:'Sofia Sans';
  }
  .changecolorM {
	filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(3.8rad) brightness(118%) contrast(119%);
}

.changecolorA {
	filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(23deg) brightness(118%) contrast(119%);
}

.changecolorR {
	filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(-34deg) brightness(118%) contrast(119%);
}

.changecolorAU {
	filter: invert(1%) sepia(79%) saturate(2476%) hue-rotate(-34deg) brightness(118%) contrast(119%);
}
.changecolorV {
	filter: invert(1000%) sepia(100%) saturate(8976%) hue-rotate(520deg) brightness(118%) contrast(999%);
}