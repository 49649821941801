.text {
  color: #fff;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
}

.textSelectionHeader {
  color: #fff;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
}

.textRegister {
  color: #fff;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  display: flex;
  align-items: center;
  text-transform: none;
}

.topMenuHeader {
  width: 110%;
  align-items: center;
  margin-left: 25%;
}