.App {

  font-family: 'Sofia Sans';
  text-align: center;
}

.title {
  font-style: normal;
  margin-bottom: 1rem;
  font-family: 'Sofia Sans';

  font-weight: 600;
  display: flex;
  align-items: center;
  color: #4CBAB7;
}

.title2 {
  font-style: normal;
  margin-bottom: 1rem;
  font-family: 'Sofia Sans';

  font-weight: 600;
  display: flex;
  align-items: center;
}

.contet {
  font-style: normal;
  font-family: 'Sofia Sans';

  color: #4CBAB7;
  font-weight: 500;
  line-height: 48px;
  display: flex;
  align-items: center;

}

.h1 {
  font-style: normal;
  font-family: 'Sofia Sans';

  font-weight: 490;
  align-items: center;
  text-transform: none;
}

.h4 {
  font-style: normal;
  font-family: 'Sofia Sans';

  font-weight: 590;
  align-items: center;
  text-transform: none;
}

.topMenuMaterial {
  width: 180%;
  height: 0px;
  font-family: 'Sofia Sans';
  list-style-type: none;
  display: flex;
  align-items: flex-start;
  justify-content: center
}

.selecteListCountryProyect {
  width: 250px;
  height: 55px;
  border-color: #4CBAB7;
  border-style: solid;
  border-radius: 19px;
  border-width: 3px;
  display: flex;
  font-size: 20px;
  color: #817b7b;
  outline: none;
  text-align: center;
}

.inputs {
  width: 95%;
  height: 35px;
  font-size: 18px;
  border-color: #4EBBB7;
  text-transform: none;
  border-style: solid;
  border-radius: 9px;
  border-width: 2px;
  color: #817b7b;
  outline: none;
  text-align: unset;
}

.inputAreatext {
  font-size: 17px;
  height: 437%;
  border-radius: 19px;
  border-color: #4EBBB7;
  text-transform: none;
  border-style: solid;
  border-radius: 19px;
  border-width: 2px;
  color: #817b7b;
  outline: none;
}