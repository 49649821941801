.App {
  
  text-align: center;
}

.title {
  font-style: normal;
  margin-bottom: 1rem;
  
  font-weight: 600;
  display: flex;
  align-items: center;
  color: #4EBBB7;
}

.inputLabel {
  font-style: italic;
  font-weight: 650;
  color: #23088E;
}

.contet {
  font-style: normal;
  
  color: #4EBBB7;
  font-weight: 500;
  line-height: 48px;
  display: flex;
  align-items: center;
}

.h1 {
  font-style: normal;
  
  font-weight: 300;
  align-items: center;
  text-transform: none;
}

.topMenuInputs {
  display: inline-flex;
}

.topSubMenuInputs {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
}

.topSubMenu2 {
  list-style-type: none;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  margin-left: -6rem;
}

.SubMenutitle {
  font-style: normal;
  color: #817b7b;
}

.selecteList {
  height: 55px;
  border-color: #4EBBB7;
  border-style: solid;
  border-radius: 19px;
  border-width: 3px;
  display: flex;
  font-size: 20px;
  color: #817b7b;
  outline: none;
  text-align: center;
}

.listtitle {
  font-style: normal;
  
  font-weight: 690;
  text-transform: none;

  display: inline-flex;
  /* Para hacer que el elemento tan solo ocupe lo que sus hijos marque */
  align-items: flex-end;
  /* Para alinear items flexibles respecto al eje secundario */

}

.listH1 {
  font-style: normal;
  
  font-weight: 490;
  text-transform: none;

}

.toplist {
  width: 100%;
  list-style-type: none;
  display: inline-flex;
  /* Para hacer que el elemento tan solo ocupe lo que sus hijos marque */
  align-items: flex-end;
  /* Para alinear items flexibles respecto al eje secundario */
  justify-content: space-between;
}

figcaption {
  display:none; 
  transition: all .5s;
}
/* Visible texto */
figure:hover > figcaption {
  display:block;
  transition: all .5s;
}